import { Dispatch, SetStateAction } from 'react';

import { CardGridProps } from './CardGrid';

export const calculateDistance = (elementOne, elementTwo) => {
  const el1 = elementOne.getBoundingClientRect();
  const el2 = elementTwo.getBoundingClientRect();

  const x1 = el1.left;
  const y1 = el1.bottom;
  const x2 = el2.left;
  const y2 = el2.bottom;

  const xDistance = x1 - x2;
  const yDistance = y1 - y2;

  return {
    x: xDistance,
    y: yDistance,
  };
};

const transform = (card, { x, y }: { [key: string]: number }) =>
  (card.style.transform = `translate(${x}px, ${y}px)`);

export const elementsAnimation = (
  cards: NodeListOf<Element>,
  initialPos: { [key: string]: number }[],
  hoveredItem: HTMLDivElement
) => {
  const distance = 850; // 450

  cards.forEach((card: any, index: number) => {
    const startValX = initialPos[index].x;
    const startValY = initialPos[index].y;

    const { x, y } = calculateDistance(card, hoveredItem);
    const absX = Math.abs(x);
    const absY = Math.abs(y);
    const atanDistanceX = Math.atan2(x, y);
    const atanDistanceY = Math.atan2(y, x);

    const translates = [
      {
        x: startValX,
        y: startValY + atanDistanceY * 2,
      },
      {
        x: startValX + atanDistanceX * 1.5,
        y: startValY + atanDistanceY * 1.5,
      },
      {
        x: startValX + atanDistanceX * 2,
        y: startValY,
      },
    ];

    if (
      absX < distance &&
      absY < distance + 150 &&
      Number(hoveredItem.dataset['idx']) !==
        Number(card.firstChild.dataset['idx'])
    ) {
      card.style.transition = 'transform 1s ease-out';

      if (absX < 200) transform(card, translates[0]);

      if (absX > 200) transform(card, translates[1]);

      if (absY < 100) transform(card, translates[2]);
    }
  });
};

interface VisibleItemsRenderData {
  addItems: number;
  setVisibleItems: Dispatch<SetStateAction<CardGridProps['items']>>;
}

export const visibleItemsRender = (
  items: CardGridProps['items'],
  filter: CardGridProps['filter'],
  data: VisibleItemsRenderData
): void => {
  const filteredItems =
    filter === 'latest'
      ? items
      : items.filter(({ tags }) => tags.split(',').includes(filter));

  const nextItems = filteredItems.slice(0, data.addItems);
  data.setVisibleItems(nextItems);
};
