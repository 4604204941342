export const distanceToMiddle = (elementOne, elementTwo) => {
  const el1 = elementOne.getBoundingClientRect();
  const el2 = elementTwo.getBoundingClientRect();

  const x1 = el1.left;
  const y1 = el1.bottom;
  const x2 = el2.left;
  const y2 = el2.bottom;

  const xDistance = x1 - x2;
  const yDistance = y1 - y2;

  return {
    x: xDistance,
    y: yDistance,
  };
};

export const renderInitialAnimation = (
  isMobile: boolean,
  id: string | number
) => {
  const dataIdSelector = document
    .querySelector(`[data-id='${id}']`)
    ?.getBoundingClientRect();

  const sharedObj = {
    translateX: 0,
    translateY: 0,
    margin: 'auto',
  };

  const mobile = {
    ...sharedObj,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  };

  const desktop = {
    ...sharedObj,
    top: dataIdSelector?.top || '9%',
    left: dataIdSelector?.left,
  };

  return isMobile ? mobile : desktop;
};
