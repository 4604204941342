import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { videoPlayerSizes } from 'utils/styles/vars';

export const Wrapper = styled(motion.div)`
  min-height: 400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 47rem;

  ${mediaTablet`
    grid-template-columns: repeat(4, 1fr);
    gap: 75rem;
  `}

  ${mediaDesktop`
    gap: 135rem;
  `}
`;

export const CardWrapper = styled(motion.div)`
  width: fit-content;
  height: fit-content;
  position: relative;
`;

export const Container = styled.div<{ itemKey: number }>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  grid-row-gap: 55rem;
  ${({ itemKey }) =>
    itemKey % 2 &&
    `
    transform: translate(0, 100px);
    padding: 0 0 150px;
  `};

  ${({ itemKey }) =>
    mediaTablet(`
      display: grid;
      inset: 0 0 620rem;
      grid-row-gap: 124rem;
      ${
        itemKey % 2 &&
        `
        transform: translate(0, 50%);
        padding: 0;
      `
      };
    `)}
`;

export const Pointer = styled.div`
  width: ${videoPlayerSizes.width};
  height: ${videoPlayerSizes.height};
  position: fixed;
  inset: 0;
  margin: 0 auto;
  pointer-events: none;
  visibility: hidden;

  ${mediaTablet(`
    inset: 0 0 620rem;
    margin: auto;
  `)}

  ${mediaDesktop(`
    inset: 0;
  `)}
`;
