import { useAnimation } from 'framer-motion';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { useInView } from 'react-intersection-observer';

import Text from 'components/Text/Text';
import { useCopyStore, useGlobalStore } from 'store';
import { ReactComponent as SvgPlay } from 'svgs/play.svg';
import useWindowSize from 'u9/hooks/useWindowSize';
import { fadeInUpMotion, opacityMotion } from 'utils/styles/animations';
import { isMobileLayout } from 'utils/styles/responsive';
import {
  breakpointDesktop,
  breakpointTablet,
  videoPlayerSizes,
} from 'utils/styles/vars';

import { distanceToMiddle, renderInitialAnimation } from './Card.utils';

import * as S from './Card.styles';

const INITIAL_COORDS = {
  x: 0,
  y: 0,
};

export interface CardProps {
  id: string | number;
  listId: string;
  imageUrl: string;
}

const defaultProps: Partial<CardProps> = {};

const Card: FunctionComponent<CardProps> = ({ id, listId, imageUrl }) => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [coords, setCoords] = useState(INITIAL_COORDS);

  const [wrapperRef, inView] = useInView({ threshold: [0.1, 0.5, 0.75, 1] });

  const { isModalVisible, selectedProject, videoOnPlay, setVideoOnPlay } =
    useGlobalStore();
  const { width, height, isLandscape } = useWindowSize(true);
  const { copy } = useCopyStore();

  const isMobile =
    width < breakpointTablet ||
    (isLandscape && width < breakpointDesktop && height < breakpointTablet) ||
    isMobileLayout();

  const animate = useAnimation();
  const animateBox = useAnimation();
  const animateContent = useAnimation();

  useEffect(() => {
    animateContent.start(fadeInUpMotion.animate);

    if (isModalVisible) {
      setVisible(true);

      const point_1 = document.querySelector('#player-pointer');
      const point_2 = document.querySelector(`[data-id='${id}']`);

      if (point_1 && point_2) setCoords(distanceToMiddle(point_1, point_2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, id]);

  useEffect(() => {
    if (inView) {
      animateContent.start(fadeInUpMotion.animate);
      animateBox.start({ scale: 1 });
    } else animateContent.start(fadeInUpMotion.initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    if (visible && id === selectedProject?.id) {
      animateContent.start({
        opacity: 0,
        transition: { opacity: { duration: 0 } },
      });

      animate.start({
        translateX: isMobile ? 0 : coords.x,
        translateY: isMobile ? 0 : coords.y,
        width: isMobile ? '100vw' : videoPlayerSizes.width,
        height: isMobile ? '100vh' : videoPlayerSizes.height,
        position: 'fixed',
        zIndex: 101,
      });

      if (videoOnPlay) animate.start({ opacity: 0, visibility: 'hidden' });
    } else animateContent.start(opacityMotion.animate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, coords, videoOnPlay, isMobile]);

  const portal = ReactDom.createPortal(
    <S.Wrapper ref={wrapperRef} onClick={() => setVideoOnPlay(true)}>
      <S.Box>
        <S.ContentWrapper
          imageUrl={imageUrl}
          data-id={id}
          initial={renderInitialAnimation(isMobile, id)}
          animate={animate}
          transition={{
            duration: 0.5,
            visibility: { delay: 1 },
            opacity: { delay: 1, duration: 0.3 },
          }}
          whileHover="animate"
        >
          <S.IconWrapper
            aria-label={copy.global.accessibility?.ctaPlay}
            variants={fadeInUpMotion}
          >
            <SvgPlay />
          </S.IconWrapper>
        </S.ContentWrapper>
      </S.Box>
    </S.Wrapper>,
    document.body
  );

  return (
    <S.Wrapper
      ref={wrapperRef}
      data-id={id}
      tabIndex={0}
      onFocus={() => animateBox.start(isLoad ? { scale: 1.1 } : { scale: 1 })}
      onBlur={() => animateBox.start({ scale: 1 })}
    >
      {visible && id === selectedProject?.id && portal}
      <S.Box
        initial={{ scale: 1.1 }}
        animate={animateBox}
        transition={
          isLoad ? { duration: 1, ease: 'easeOut' } : { duration: 1.5 }
        }
        onHoverStart={() =>
          animateBox.start(isLoad && !isMobile ? { scale: 1.1 } : { scale: 1 })
        }
        onHoverEnd={() => animateBox.start({ scale: 1 })}
        onAnimationComplete={() => !isLoad && setIsLoad(true)}
        style={!isLoad ? { pointerEvents: 'none' } : {}}
      >
        {listId === '0-2' && copy.index.ourWork.description?.length > 0 && (
          <S.TextWrapper
            {...fadeInUpMotion}
            transition={{ delay: 1.2, duration: 0.6 }}
          >
            <Text text={copy.index.ourWork.description} size="body3" />
          </S.TextWrapper>
        )}

        <S.ContentWrapper
          animate={animateContent}
          transition={{ duration: 0.3 }}
          whileHover="animate"
          imageUrl={imageUrl}
        >
          <S.IconWrapper
            aria-label={copy.global.accessibility?.ctaPlay}
            variants={fadeInUpMotion}
          >
            <SvgPlay />
          </S.IconWrapper>
        </S.ContentWrapper>
      </S.Box>
    </S.Wrapper>
  );
};

Card.defaultProps = defaultProps;

export default memo(Card);
