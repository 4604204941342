import { chunk } from 'lodash';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactDom from 'react-dom';

import Card from 'components/Card/Card';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useGlobalStore } from 'store';
import { ProjectProps } from 'store/copy.types';
import useWindowSize from 'u9/hooks/useWindowSize';
import { fadeInUpMotion } from 'utils/styles/animations';
import { breakpointTablet } from 'utils/styles/vars';

import { elementsAnimation, visibleItemsRender } from './CardGrid.utils';

import * as S from './CardGrid.styles';

const NO_OF_INITIAL_ITEMS = 8;
const NO_OF_ITEMS_TO_LOAD = 4;

export interface CardGridProps {
  items: ProjectProps[];
  filter?: string;
}

const defaultProps: Partial<CardGridProps> = {
  items: [],
  filter: 'latest',
};

const CardGrid: FunctionComponent<CardGridProps> = ({ items, filter }) => {
  const [visibileItems, setVisibleItems] = useState<ProjectProps[]>([]);
  const [gridItems, setGridItems] = useState<ProjectProps[][]>([[]]);
  const [hoveredTile, setHoveredTile] = useState<number | null>(null);
  const [initialPos, setInitialPos] = useState<{ x: number; y: number }[]>([]);
  const [initialAnimationEnd, setInitialAnimationEnd] =
    useState<boolean>(false);

  const size = useWindowSize(true);
  const {
    setFilteredItems,
    setSelectedProject,
    setModalVisible,
    isModalVisible,
  } = useGlobalStore();

  useEffectOnce(() => {
    const timeout = setTimeout(() => setInitialAnimationEnd(true), 2000);

    return () => clearInterval(timeout);
  });

  useEffect(() => {
    const filteredItems =
      filter === 'latest'
        ? items
        : items.filter(({ tags }) => tags.split(',').includes(filter));

    setFilteredItems(filteredItems);
  }, [filter]);

  useEffect(() => {
    visibleItemsRender(items, filter, {
      addItems: NO_OF_INITIAL_ITEMS,
      setVisibleItems,
    });
  }, [filter, items]);

  useEffect(() => {
    const percentageScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercentage = (scrollPosition / totalHeight) * 100;

      if (
        Math.round(scrollPercentage) >= 65 &&
        visibileItems.length < items.length
      ) {
        visibleItemsRender(items, filter, {
          addItems: visibileItems.length + NO_OF_ITEMS_TO_LOAD,
          setVisibleItems,
        });
      }
    };

    window.addEventListener('scroll', percentageScroll);

    return () => window.removeEventListener('scroll', percentageScroll);
  }, [filter, visibileItems]);

  useEffect(() => {
    setGridItems(
      chunk(
        visibileItems,
        size.width >= breakpointTablet ? 1 : (visibileItems.length + 1) / 2
      )
    );
  }, [filter, visibileItems, size]);

  useEffect(() => {
    const cards = document.querySelectorAll('.card-wrapper');
    const arr = [];

    cards.forEach(sqr => {
      const { m41, m42 } = new DOMMatrixReadOnly(
        getComputedStyle(sqr).transform
      );

      arr.push({ x: m41, y: m42 });
    });

    setInitialPos(arr);
  }, [items, gridItems]);

  const handleClick = (project: ProjectProps) => {
    if (initialAnimationEnd) {
      setSelectedProject(project);
      setModalVisible(true);
    }
  };

  const handleHoverStart = useCallback(
    e => {
      const hoveredItem = e.target;

      if (initialAnimationEnd) {
        setHoveredTile(Number(e.target.dataset['idx']));

        const cards = document.querySelectorAll('.card-wrapper');

        initialPos.length >= cards.length &&
          elementsAnimation(cards, initialPos, hoveredItem);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialPos, hoveredTile, initialAnimationEnd]
  );

  const handleHoverEnd = () => {
    setHoveredTile(null);

    const cards = document.querySelectorAll('.card-wrapper');
    cards.forEach((sqr: HTMLDivElement, index: number) => {
      sqr.style.transition = 'transform 1s ease-out';
      sqr.style.transform = index % 2 ? '' : 'translate(0, 0)';
    });
  };

  const portal =
    isModalVisible &&
    ReactDom.createPortal(<S.Pointer id="player-pointer" />, document.body);

  return (
    <S.Wrapper>
      {portal}
      {gridItems.map((items, key) => (
        <S.Container key={key} itemKey={key} className="card-wrapper">
          {items.map((item: ProjectProps, index) => (
            <S.CardWrapper
              key={index}
              data-idx={item?.id}
              onClick={() => handleClick(item)}
              onHoverStart={handleHoverStart}
              onHoverEnd={handleHoverEnd}
              onFocus={handleHoverStart}
              onBlur={handleHoverEnd}
              onKeyPress={e => e.key === 'Enter' && handleClick(item)}
              {...fadeInUpMotion}
              transition={{ delay: 0.2 * (index + 1), duration: 0.4 }}
              custom={+item?.id}
            >
              <Card
                id={item?.id}
                listId={`${key}-${index}`}
                imageUrl={item?.poster?.url}
              />
            </S.CardWrapper>
          ))}
        </S.Container>
      ))}
    </S.Wrapper>
  );
};

CardGrid.defaultProps = defaultProps;

export default memo(CardGrid);
