import { motion } from 'framer-motion';
import styled from 'styled-components';

import {
  mediaDesktop,
  mediaDesktopExtraWide,
  mediaTablet,
} from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface ContentWrapperProps {
  imageUrl: string;
}

export const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Box = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
`;

export const TextWrapper = styled(motion.div)`
  padding: 0;
  text-align: left;
  display: grid;
  padding: 0 0 85rem;
  width: 100%;

  ${mediaTablet(`
    display: none;
  `)}
`;

export const ContentWrapper = styled(motion.div)<ContentWrapperProps>`
  width: 426rem;
  height: 625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20rem;
  box-shadow: rgb(2 103 129 / 50%) 0px 2px 18px 0px;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-position: center;
    background-size: cover;
    ${({ imageUrl }) => imageUrl && `background-image: url('${imageUrl}')`};
    transition: all 0.3s ease-in-out;
  }

  ${mediaTablet`
    width: 420rem;
    height: 650rem;
  `}

  ${mediaDesktop`
    width: 680rem;
    height: 999rem;
  `}

  ${mediaDesktopExtraWide`
    width: 560rem;
  `}
`;

export const IconWrapper = styled(motion.div)`
  width: 184rem;
  height: 184rem;
  opacity: 0;
  color: ${colors.alizarinCrimson};
  position: relative;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
  }
`;
